.footer {
  /* width: calc(100% + 44px); */
  /* position: fixed;
    bottom: 0; */
  width: 100%;
  /* max-width: 360px; */
  margin-top: 80px;
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 0 20px 30px 20px;
  /* border: solid 1px rgba(151, 151, 151, 0.5); */
  /* background-color: #e0dede; */

  /* padding: 44px 36px; */
  /* padding: 20px; */
  /* 
      margin-top: 32px; */
}

.footerSimple {
  display: flex;
  justify-content: center; /* Horizontally centers the content */
  padding: 20px; /* Optional: Adjust the padding as needed */
}

.tagline {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  /* font-weight: bold; */
  font-weight: 700;
  color: #1c3142;
  letter-spacing: 1.5px;
  margin-left: 4px;
  /* height: 105px;
      width: 300px; */
  /* 
      margin-bottom: 44px; */
}
.poweredBy {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #8e8e8e;
  margin-bottom: 4px;
}
.footerLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0.8;
  font-size: 0.9rem;
  /* margin: 1.5rem 0 0.8rem 0; */
}
.quickLinks {
  display: flex;
  justify-content: flex-start;
  /* margin: 0px 20px; */
  flex-direction: column;
}
.heading {
  font-weight: bold;
  font-size: 18px;
  margin: 16px 0px 10px 0px;
}

.quickLinks a {
  line-height: 2px;
}

.aboutDetails {
  color: slategray;
  line-height: 1.5rem;
}
.images {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.images img {
  width: 50px; /* set desired width */
  height: 50px; /* set desired height */
}
.regularQuickLink {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.regularQuickLink a {
  margin: 0;
  flex: 1;
  text-align: center;
}
