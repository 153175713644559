.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 95;
}
